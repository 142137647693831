define("@frontile/forms/components/form-radio", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormField
    @size={{@size}}
    class={{
      use-frontile-class
      "form-radio"
      @size
      class=(array @containerClass @privateContainerClass)
    }} as |f|
  >
    <div class={{use-frontile-class "form-radio" @size part="label-container"}}>
      <div class={{use-frontile-class "form-radio" @size part="input-container"}}>
        {{!  Zero-width space character, used to align checkbox properly }}
        ​
        <f.Radio
          @onChange={{this.handleChange}}
          @value={{@value}}
          @checked={{@checked}}
          @name={{@name}}
          class={{use-frontile-class "form-radio" @size part="radio"}}
          aria-describedby={{if @hint f.hintId}}
          ...attributes
        />
      </div>
  
      <f.Label class={{use-frontile-class "form-radio" @size part="label"}}>
        {{#if (has-block)}}
          {{yield}}
        {{else}}
          {{@label}}
        {{/if}}
      </f.Label>
    </div>
  
    {{#if @hint}}
      <f.Hint class={{use-frontile-class "form-radio" @size part="hint"}}>
        {{@hint}}
      </f.Hint>
    {{/if}}
  </FormField>
  */
  {
    "id": "tcPelgQw",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"form-radio\",[30,1]],[[\"class\"],[[28,[37,2],[[30,2],[30,3]],null]]]]]],[[\"@size\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[28,[37,1],[\"form-radio\",[30,1]],[[\"part\"],[\"label-container\"]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[28,[37,1],[\"form-radio\",[30,1]],[[\"part\"],[\"input-container\"]]]],[12],[1,\"\\n\"],[1,\"      ​\\n      \"],[8,[30,4,[\"Radio\"]],[[16,0,[28,[37,1],[\"form-radio\",[30,1]],[[\"part\"],[\"radio\"]]]],[16,\"aria-describedby\",[52,[30,5],[30,4,[\"hintId\"]]]],[17,6]],[[\"@onChange\",\"@value\",\"@checked\",\"@name\"],[[30,0,[\"handleChange\"]],[30,7],[30,8],[30,9]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[30,4,[\"Label\"]],[[16,0,[28,[37,1],[\"form-radio\",[30,1]],[[\"part\"],[\"label\"]]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,11]],[[[1,\"        \"],[18,11,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,10]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"    \"],[8,[30,4,[\"Hint\"]],[[16,0,[28,[37,1],[\"form-radio\",[30,1]],[[\"part\"],[\"hint\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,5]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[4]]]]]],[\"@size\",\"@containerClass\",\"@privateContainerClass\",\"f\",\"@hint\",\"&attrs\",\"@value\",\"@checked\",\"@name\",\"@label\",\"&default\"],false,[\"form-field\",\"use-frontile-class\",\"array\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@frontile/forms/components/form-radio.hbs",
    "isStrictMode": false
  });
  let FormRadio = (_class = class FormRadio extends _component2.default {
    handleChange(value, event) {
      event.preventDefault();
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
      if (typeof this.args._parentOnChange === 'function') {
        this.args._parentOnChange(value, event);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  _exports.default = FormRadio;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormRadio);
});